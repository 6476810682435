const siteMetadata = {
    title: `Enzo Maolucci`,
    siteUrl: `https://www.enzomaolucci.com`,
    capitalizeTitleOnHome: false,
    logo: `/images/em-logo.png`,
    icon: `/images/icon-em.png`,
    titleImage: `/images/de-liberata-mente.jpg`,
    ogImage: `/images/de-liberata-mente.jpg`,
    twoColumnWall: true,
    cookiePolicy: false,
    introTag: `De-Liberata-Mente`,
    description: `Tutte le informazioni sulla musica di Enzo Maolucci`,
    about:
        "Biografia, testi, media, tutto su Enzo Maolucci e la sua musica",
    author: `Beppe Donati`,
    blogItemsPerPage: 10,
    portfolioItemsPerPage: 10,
    darkmode: true,
    switchTheme: true,
    navLinks: [
        {
            name: "HOME",
            url: "/",
        },
        {
            name: "BIOGRAFIA",
            url: "/biografia",
        },
        {
            name: "NEWS E ALTRO",
            url: "/blog",
        },
        {
            name: "DISCOGRAFIA",
            url: "/portfolio",
        },
        {
            name: "MEDIA",
            url: "/media",
        },
        {
            name: "CONTATTI",
            url: "/contact",
        },
    ],
    footerLinks: [
        {
            name: "MEDIA ",
            url: "/media",
        },
    ],
    social: [
        {
            name: "Facebook",
            icon: "/images/Facebook.svg",
            url: "https://www.facebook.com/Enzo-Maolucci-51049261721",
        },  
        {
            name: "Youtube",
            icon: "/images/Youtube.svg",
            url: "https://www.youtube.com/results?search_query=enzo+maolucci",
        },
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://getform.io/f/80414974-666d-46f0-921d-1260ace59634",
        description: `I vostri messaggi e richieste verranno inoltrati a Enzo Maolucci`,
        mail: "",
        phone: "",
        address: "",
    },
    disqus: "elemental-netlify-com",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Inserisci un nome",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Inserisci un indirizzo email valido",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Inserisci un messaggio di almeno 15 caratteri",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
    cookiePolicy: false
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
